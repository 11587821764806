<template>
	<div class="container">
			<h4>Lista firm</h4>
			<!-- <h3>{{ content }}</h3> -->
 <ul class="responsive-table">
    <li class="table-header">
      <div class="col col-1">Id</div>
      <div class="col col-2">Nazwa</div>
      <div class="col col-3">Miejscowość</div>
      <div class="col col-4">Kod pocztowy</div>
		<div class="col col-4">Adres</div>
    </li>
    <li class="table-row" v-for="cont in content" :key="cont.id">
      <div class="col col-1" data-label="Id">{{cont.id}}</div>
          <!-- <div v-if="cont.id === 1">
            <img src="..//static/images/img1.png" alt="" />
          </div>
          <div v-else-if="cont.id === 2">
              <img src="..//static/images/img2.png" alt="" />
          </div>
          <div v-else-if="cont.id === 3">
            <img src="..//static/images/img3.png" alt="" />
          </div>
          <div v-else>
            <img src="..//static/images/img4.png" alt="" />
          </div>  -->
      <div class="col col-2" data-label="Nazwa">{{cont.name}}</div>
      <div class="col col-3" data-label="Miejscowość">{{cont.city}}</div>
      <div class="col col-4" data-label="Kod pocztowy">{{cont.code}}</div>
		<div class="col col-4" data-label="Adres">{{cont.street}}</div>
    </li>
 </ul>
</div>
	<!-- <table class="table table-bordered">
    <thead>
      <tr>
        <h4><th scope="col">id</th></h4>
        <th scope="col">Nazwa</th>
        <th scope="col">Miasto</th>
        <th scope="col">Ulica</th>
        <th scope="col">Kod pocztowy</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="cont in content" :key="cont.id">
        <td>{{cont.id}}</td>
        <td>{{cont.name}}</td>
        <td>{{cont.city}}</td>
        <td>{{cont.street}}</td>
        <td>{{cont.code}}</td>
      </tr>
    </tbody>
  </table> -->
</template>

<script>
import UserService from "../services/user.service";

export default {
  name: "Admin",
  data() {
    return {
      content: "",
    };
  },
  mounted() {
    UserService.getCompany().then(
      (response) => {
        this.content = response.data;
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
};
</script>

<style>
.container {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
}

h2 {
  font-size: 26px;
  margin: 20px 0;
  text-align: center;
}
h2 small {
  font-size: 0.5em;
}

.responsive-table li {
  border-radius: 3px;
  padding: 25px 30px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}
.responsive-table .table-header {
  background-color: #000000;
  color: #ffffff;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.03em;
}
.responsive-table .table-row {
  background-color: #ffffff;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
}
.responsive-table .col-1 {
  flex-basis: 10%;
}
.responsive-table .col-2 {
  flex-basis: 40%;
}
.responsive-table .col-3 {
  flex-basis: 25%;
}
.responsive-table .col-4 {
  flex-basis: 25%;
}
@media all and (max-width: 767px) {
  .responsive-table .table-header {
    display: none;
  }
  .responsive-table li {
    display: block;
  }
  .responsive-table .col {
    flex-basis: 100%;
  }
  .responsive-table .col {
    display: flex;
    padding: 10px 0;
  }
  .responsive-table .col:before {
    color: #6C7A89;
    padding-right: 10px;
    content: attr(data-label);
    flex-basis: 50%;
    text-align: right;
  }
}
</style>
